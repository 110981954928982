import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import App from './App.jsx';

import Cookies from '../components/Cookies.jsx';
import handlerPopup from '../functions/handlerPopup';
import Animate from '../components/Animate.jsx';
import EmailPopup from '../components/EmailPopup.jsx';
import OrderPopup from '../components/OrderPopup.jsx';
import CodePopup from '../components/CodePopup.jsx';
import GamePopup from '../components/GamePopup.jsx';
import SpecialPrizeInfoPopup from '../components/SpecialPrizeInfoPopup.jsx';
import SoundsPopup from '../components/SoundsPopup.jsx';
import ReferalPopup from '../components/ReferalPopup.jsx';
import getCurrentPage from '../functions/getCurrentPage';
import Pages from '../components/Pages.jsx';
import Registration from './Registration.jsx';
import Cheques from './Cheques.jsx';
import Login from './Login.jsx';
import InfoPopup from '../components/InfoPopup.jsx';

class Root extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    pages = {
        login: {
            render() {
                return (
                    <>
                        <Login />
                    </>
                );
            },
        },
        registration: {
            render() {
                return (
                    <>
                        <Registration />
                    </>
                );
            },
        },
        cheques: {
            render() {
                return (
                    <>
                        <Cheques />
                    </>
                );
            },
        },
    };

    componentDidMount() {
        const search = window.location.search?.slice(1);

        if (search) {
            const searchItems = search.split('&');
            const inv = searchItems.find((item) => item.split('=')[0] === 'inv');

            if (inv) {
                const value = inv.split('=')[1];

                if (value) {
                    localStorage.setItem('inviteCode', value);
                }
            }
        }

        if (!localStorage.getItem('showInfoPopup')) {
            handlerPopup({ name: 'infoPopup', isShow: true });
        }
    }

    render() {
        const { isLoadFonts, windowIsLoad, device } = this.props;
        const {
            emailPopup,
            orderPopup,
            codePopup,
            gamePopup,
            specialPrizeInfoPopup,
            soundsPopup,
            referalPopup,
            loades,
            storePages,
            infoPopup,
            mainContent,
        } = this.props;

        const currentPopupPage = getCurrentPage({
            pages: storePages,
            filter: (page) => page.isPopup,
        });
        const rulesHref = mainContent?.footer?.url3?.url;

        return (
            <>
                <div className={`body__main ${isLoadFonts ? '_fontsLoad' : ''}`}>
                    <Cookies />
                    <Animate
                        className="body__popupBack"
                        isShow={
                            !!currentPopupPage ||
                            emailPopup?.isShow ||
                            orderPopup?.isShow ||
                            codePopup?.isShow ||
                            gamePopup?.isShow ||
                            soundsPopup?.isShow ||
                            referalPopup?.isShow ||
                            infoPopup?.isShow ||
                            (device === 'mobile' && specialPrizeInfoPopup?.isShow)
                        }
                    />
                    {loades[12] && (
                        <>
                            {' '}
                            <Animate className="body__popup" isShow={emailPopup?.isShow}>
                                <EmailPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={orderPopup?.isShow}>
                                <OrderPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={codePopup?.isShow}>
                                <CodePopup />
                            </Animate>
                            <Animate className="body__popup" isShow={gamePopup?.isShow}>
                                <GamePopup />
                            </Animate>
                            <Animate className="body__popup" isShow={soundsPopup?.isShow}>
                                <SoundsPopup />
                            </Animate>
                            <Animate className="body__popup" isShow={referalPopup?.isShow}>
                                <ReferalPopup />
                            </Animate>
                        </>
                    )}

                    <Animate className="body__popup" isShow={infoPopup?.isShow}>
                        <InfoPopup />
                    </Animate>

                    {device === 'mobile' && (
                        <Animate className="body__popup" isShow={specialPrizeInfoPopup?.isShow}>
                            <SpecialPrizeInfoPopup />
                        </Animate>
                    )}

                    <Pages
                        className="body__pages _popup"
                        classNamePage="body__page"
                        filter={(page) => Object.keys(this.pages).includes(page.name)}
                        pages={this.pages}
                        context={this}
                    />

                    <div
                        className="body__info"
                        onClick={() => {
                            handlerPopup({ name: 'infoPopup', isShow: true });
                        }}
                    >
                        {device === 'mobile' ? (
                            <>
                                Акция продлевается до 31.08.2024, см.{' '}
                                <a
                                    href={rulesHref}
                                    target="_blank"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    Правила акции
                                </a>
                            </>
                        ) : (
                            <>
                                Уважаемые участники, информируем, что срок регистрации кодов и чеков
                                продлевается до 31.08.2024 г. Все подробные изменения отражены в
                                <a
                                    href={rulesHref}
                                    target="_blank"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    Правилах акции
                                </a>
                            </>
                        )}
                    </div>
                    <div
                        ref={this.parent}
                        className={`body__content ${windowIsLoad ? '_isLoad' : ''}`}
                    >
                        <App />
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        windowIsLoad: state.windowIsLoad,
        isMenuShow: state.isMenuShow,
        projectsGuidePopup: state.projectsGuidePopup,
        windowHeight: state.windowHeight,
        isLoadSite: state.isLoadSite,
        windowIsReady: state.windowIsReady,
        isLoadVersion: state.isLoadVersion,
        storePages: state.pages,
        isLoadFonts: state.isLoadFonts,
        isInputFocus: state.isInputFocus,
        loadingImage: state.loadingImage,
        user: state.user,
        orderPopup: state.orderPopup,
        codePopup: state.codePopup,
        emailPopup: state.emailPopup,
        gamePopup: state.gamePopup,
        specialPrizeInfoPopup: state.specialPrizeInfoPopup,
        soundsPopup: state.soundsPopup,
        referalPopup: state.referalPopup,
        loades: state.loades,
        infoPopup: state.infoPopup,
        mainContent: state.mainContent,
    };
}

export default connect(mapStateToProps)(Root);

Root.propTypes = {
    device: PropTypes.string,
    windowIsLoad: PropTypes.any,
    isMenuShow: PropTypes.bool,
    projectsGuidePopup: PropTypes.object,
    windowHeight: PropTypes.number,
    isLoadSite: PropTypes.bool,
    windowIsReady: PropTypes.bool,
    isLoadVersion: PropTypes.bool,
    storePages: PropTypes.object,
    isLoadFonts: PropTypes.bool,
    isInputFocus: PropTypes.bool,
    loadingImage: PropTypes.string,
    user: PropTypes.object,
    orderPopup: PropTypes.object,
    emailPopup: PropTypes.object,
    codePopup: PropTypes.object,
    gamePopup: PropTypes.object,
    specialPrizeInfoPopup: PropTypes.object,
    soundsPopup: PropTypes.object,
    referalPopup: PropTypes.object,
    loades: PropTypes.array,
    infoPopup: PropTypes.object,
    mainContent: PropTypes.object,
};
