import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Popup from './Popup.jsx';
import Button from './Button.jsx';

import handlerPopup from '../functions/handlerPopup';

class InfoPopup extends Popup {
    constructor(props) {
        super(props);
        this.state = {};

        this.parent = React.createRef();
    }

    render() {
        const { mainContent } = this.props;
        const rulesHref = mainContent?.footer?.url3?.url;

        return (
            <Popup name="infoPopup">
                <div className="popup__head">
                    <div className="popup__headTitle">Продление акции</div>
                </div>
                <div className="popup__content">
                    <div className="popup__info">
                        <div className="popup__infoTitle">
                            Уважаемые участники, информируем, что срок регистрации кодов и чеков
                            продлевается до 31.08.2024 г.
                        </div>
                        <div className="popup__infoDescription">
                            Все подробные изменения отражены в&nbsp;
                            <a href={rulesHref} target="_blank">
                                Правилах акции
                            </a>
                        </div>
                    </div>
                </div>
                <div className="popup__foot">
                    <div className="popup__button _wide">
                        <Button
                            className="_red _2 _mediumSize"
                            onClick={() => {
                                handlerPopup({
                                    name: 'infoPopup',
                                    isShow: false,
                                });
                            }}
                        >
                            Закрыть
                        </Button>
                    </div>
                </div>
            </Popup>
        );
    }
}

function mapStateToProps(state) {
    return {
        device: state.device,
        user: state.user,
        mainContent: state.mainContent,
    };
}

export default connect(mapStateToProps)(InfoPopup);

InfoPopup.propTypes = {
    device: PropTypes.string,
    user: PropTypes.object,
    mainContent: PropTypes.object,
};
